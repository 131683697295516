<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
        sm="12"
        lg="4"
      >
        <base-material-chart-card
          color="red"
          icon="mdi-key-plus"
          title="Registrations"
          :item="register"
        />
      </v-col>

      <v-col
        cols="12"
        sm="12"
        lg="4"
      >
        <base-material-chart-card
          color="yellow"
          icon="mdi-shield-check"
          title="Authentications"
          :item="authn"
        />
      </v-col>

      <v-col
        cols="12"
        sm="12"
        lg="4"
      >
        <base-material-chart-card
          color="indigo"
          icon="mdi-lock-open-alert"
          title="Login Failed"
          :item="loginFailed"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <base-material-log-table
          color="green"
          icon="mdi-update"
          inline
          title="System Logs"
          :url="logs"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'DashboardDashboard',

    data () {
      return {
        // charts
        register: {
          url: '/v1/api/dashboard/keys',
          keyword: 'chartsData',
        },
        authn: {
          url: '/v1/api/dashboard/auth',
          keyword: 'chartsData',
        },
        loginFailed: {
          url: '/v1/api/dashboard/failed',
          keyword: 'chartsData',
        },

        // table
        logs: '/v1/api/dashboard/logs',
      }
    },
  }
</script>

<style lang="sass">
  .v-data-table>.v-data-table__wrapper>table>tbody>tr>th, .v-data-table>.v-data-table__wrapper>table>tfoot>tr>th, .v-data-table>.v-data-table__wrapper>table>thead>tr>th
    font-size: .85rem !important
</style>
